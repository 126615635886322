.cell-center {
  text-align: center;
}
.cell-center > * {
  margin: 0 auto;
}

.cell-right {
  text-align: right;
}
.cell-right > * {
  margin: 0 0 0 auto;
}

.sticky-header {
  margin: 0;
  position: sticky;
  top: -1px;
  z-index: 2;
}
