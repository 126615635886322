#root {
  height: 100vh;
}

.MuiTableCell-head {
  text-transform: initial;
}

label {
  overflow: visible !important;
}

.remove-arrow-input::-webkit-outer-spin-button,
.remove-arrow-input input::-webkit-outer-spin-button,
.remove-arrow-input::-webkit-inner-spin-button,
.remove-arrow-input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.remove-arrow-input[type='number'],
.remove-arrow-input input[type='number'] {
  -moz-appearance: textfield;
}

.html-content img {
  max-width: 100%;
  width: 100%;
  height: auto;
}

.input-submit-form {
  width: 100%;
  padding: 8px;
  border: 1px solid rgb(175, 175, 175);
  background-color: transparent;
  transition: 0.2s;
  border-radius: 4px;
}

.input-submit-form:focus {
  outline: none;
  border-color: grey;
}

.input-submit-form.error {
  border-color: red;
}
